/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import {
  Box,
  ButtonBase,
  LinearProgress,
  Typography,
  styled,
} from '@mui/material';
import React, { forwardRef } from 'react';
import xlsxIcon from '../../assets/img/xlsxIcon.svg';
import greenTick from '../../assets/img/greenTick.svg';
import greenUploadIcon from '../../assets/img/greenUploadIcon.svg';
import exclamationMark from '../../assets/img/exclamationMark.svg';
import { formatBytes } from '../../utils/helpers';

import MESSAGE_STRINGS from '../../constants/en-us';
import { ACCORDION_STATUS } from '../../constants';
const ProgressContainer = styled(Box)(({ theme }) => ({
  width: '25.625rem',
  backgroundColor: theme.palette.background.elevationGrey,
  padding: '0.75rem',
  boxShadow: '0 0.25em 0.25em rgba(0, 0, 0, 0.25)',
  borderRadius: '0.25rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '0.438rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.customColors.progressBarEmpty,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.customColors.progressBarBlue,
  },
}));

const SmallUploadIcon = styled('div')(() => ({
  height: '3.125rem',
  width: '3.125rem',
}));

const FileSizeTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '1.362rem',
  color: theme.palette.text.blockGrey,
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: '1rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '1.362rem',
  marginTop: '0.625rem',
}));

const UploadPreview = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({
    previewComponentProps,
    handleReupload,
    isFileUploadedWithoutErrors,
    isFileUploaded,
    errorList,
    statusFault,
    isUploadSocketError,
  }) => {
    // eslint-disable-next-line no-unused-vars
    const { meta: { name, size, percent } = {}, fileWithMeta } =
      previewComponentProps;

    return (
      <div>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <ProgressContainer data-testid="progresss-container">
              <div style={{ flexGrow: '0.2' }}>
                <img src={xlsxIcon} alt="xlsx-icon" />
              </div>
              <Box
                sx={{
                  flexGrow: '1',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginLeft: '2rem',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <div>{name}</div>
                </Box>
                <FileSizeTypography>{formatBytes(size)}</FileSizeTypography>
                <StyledLinearProgress
                  variant="determinate"
                  value={Math.round(percent)}
                />
              </Box>

              {/* // ? icons */}
              <>
                {!isFileUploaded &&
                  errorList.length > 0 &&
                  isUploadSocketError && (
                    <Box sx={{ flexGrow: '0.2', marginLeft: '2.125rem' }}>
                      <img src={exclamationMark} alt="error-icon" />
                    </Box>
                  )}
                {isFileUploaded && statusFault === ACCORDION_STATUS.COMPLETED && (
                  <Box sx={{ flexGrow: '0.2', marginLeft: '2.125rem' }}>
                    <img src={greenTick} alt="error-icon" />
                  </Box>
                )}
              </>
            </ProgressContainer>
          </div>
          {!isFileUploadedWithoutErrors &&
            errorList.length > 0 &&
            isUploadSocketError && (
              <Box
                sx={{
                  flexGrow: '1',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '1.5rem',
                }}
              >
                <ButtonBase
                  onClick={() => handleReupload(fileWithMeta)}
                  disableRipple
                  id="upload_preview"
                >
                  <SmallUploadIcon>
                    <img
                      src={greenUploadIcon}
                      alt="reupload-icon"
                      style={{ maxWidth: '100%' }}
                    />
                  </SmallUploadIcon>
                </ButtonBase>
                <StyledTypography>
                  {MESSAGE_STRINGS['UploadComponent.reUpload.text']}
                </StyledTypography>
              </Box>
            )}
        </Box>
      </div>
    );
  }
);

export default UploadPreview;
