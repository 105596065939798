import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Box, Grid, Typography, alpha, styled } from '@mui/material';

import {
  CONSTRAINT_ACCORDION_TITLES,
  TOAST_REDUCER_CONSTANTS,
  MISCELLANEOUS_CONSTANTS,
  BUTTON_TYPE,
  ACCORDION_STATUS,
  BUTTON_LABELS,
} from '../../constants';

import ErrorBox from '../../components/ErrorBox';
import HourMinInputs from '../../components/HourMinInputs';
import GeneralButton from '../../components/GeneralButton/index';

import { CONSTANT_MESSAGES, ERROR_MESSAGES } from '../../constants/en-us';

import {
  saveMiscData,
  retrieveMiscData,
  queryConstants,
} from '../../utils/apihelper';

import { useToastContext } from '../../context/toastContext';
import { useRxjsState } from '../../utils/useRxjsState';

const HeaderMsgGrid = styled(Grid)(({ theme }) => ({
  padding: '.25rem .5rem',
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '0rem',

  alignItems: 'center',

  height: '3.5rem',
  flexGrow: '1',
  backgroundColor: theme.customColors.darkishBlackBlue,

  borderTop: `1px solid ${theme.customColors.targetLightGrey}`,
  borderBottom: `1px solid ${theme.customColors.targetLightGrey}`,
}));

const HeaderParamGrid = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex',
  padding: '.25rem .5rem',
  paddingLeft: '1.25rem',
  height: '3.5rem',
  alignItems: 'center',

  borderBottom: `1px solid ${theme.customColors.targetLightGrey}`,
  borderTop: `1px solid ${theme.customColors.targetLightGrey}`,
  backgroundColor: theme.customColors.darkishBlackBlue,
  width: '69.133%',
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: '.875rem',
  fontWeight: '400',
  fontStyle: 'normal',
  fontFamily: 'open sans',
  lineHeight: '1.25rem',
  color: theme.customColors.lightUnitGrey,
}));

const HrMinContainerGrid = styled(Grid)(() => ({
  direction: 'column',
  justifyContent: 'flex-start',
  width: '13rem',
  marginTop: '.5rem',
  marginBottom: '.5rem',
  flexGrow: '1',
}));

const MsgContainerGrid = styled(Grid)(() => ({
  paddingLeft: '1.25rem',
  width: '69.133%',
}));

const ButtonContainerGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '3.5rem',
  boxSizing: 'border-box',
  justifyContent: 'center',
  backgroundColor: alpha(theme.customColors.darkishBlackBlue, 0.5),

  width: '100%',
  padding: '.5625rem 1rem .5rem 1rem ',

  gap: '.625em',
  alignItems: 'flex-end',

  borderBottom: `1px solid ${theme.customColors.targetLightGrey}`,
  borderTop: `1px solid ${theme.customColors.targetLightGrey}`,
}));

const MiscellaneousSettingsContainer = ({ handleStatus = () => {} }) => {
  const [isInitialEntry, setisInitialEntry] = useState(false);
  const [buttonHit, setButtonHit] = useState(false);
  const [maxSnoozeTime, setMaxSnoozeTime] = useState({ hr: null, min: null });

  const queryClient = useQueryClient();
  const { rxjsState } = useRxjsState();
  const { toastDispatch } = useToastContext();

  const { mutate: postMiscDataMutate } = useMutation(
    (data) => saveMiscData(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryConstants.GET_STATUS_DATA);
        handleStatus(
          ACCORDION_STATUS.COMPLETED,
          CONSTRAINT_ACCORDION_TITLES.MISCELLANEOUS_SETTINGS
        );
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: CONSTANT_MESSAGES.MISC.POST_SUCCESS,
          },
        });
      },
      onError: (error) => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message:
              error?.response?.data?.message ??
              CONSTANT_MESSAGES.DEFAULT_POST_API_ERROR_MESSAGE,
          },
        });
      },
    }
  );

  useQuery(
    [queryConstants.GET_MISC_DATA],
    async () => {
      return await retrieveMiscData({ plantId: rxjsState.factoryId });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!rxjsState.factoryId,
      onSuccess: (response) => {
        const modifiedResponse = {
          hr: response?.misc?.maxSnoozeTimeValue
            ? Math.floor(response?.misc?.maxSnoozeTimeValue / 60).toString()
            : null,
          min: response?.misc?.maxSnoozeTimeValue
            ? Math.round(response?.misc?.maxSnoozeTimeValue % 60).toString()
            : null,
        };
        setMaxSnoozeTime(modifiedResponse);
        if (modifiedResponse.hr === null && modifiedResponse.min === null) {
          setisInitialEntry(true);
        }
      },
    }
  );

  const getErrorMessage = (timeValue) => {
    if (timeValue === null && isInitialEntry) {
      return;
    }
    if (!timeValue || isBothHourAndMinZero()) {
      return ERROR_MESSAGES.REQUIRED;
    }
    if (timeValue % 1 > 0) {
      return ERROR_MESSAGES.NOT_WHOLE_NUMBER;
    }
  };
  const isTimeError = (timeValue) => {
    if (timeValue === null && isInitialEntry) {
      return false;
    }
    if (timeValue % 1 > 0) {
      return true;
    }
    if (isBothHourAndMinZero()) {
      return true;
    }
    if (buttonHit && !timeValue) {
      return true;
    } else {
      return false;
    }
  };

  const isBothHourAndMinZero = () => {
    return (
      parseInt(maxSnoozeTime.hr) === 0 && parseInt(maxSnoozeTime.min) === 0
    );
  };

  // eslint-disable-next-line no-unused-vars
  const handleChange = (e, _label, isHour) => {
    handleStatus(
      ACCORDION_STATUS.IN_PROGRESS,
      CONSTRAINT_ACCORDION_TITLES.MISCELLANEOUS_SETTINGS
    );
    setButtonHit(true);
    const value = e.target.value;
    if (isHour) {
      setMaxSnoozeTime({
        ...maxSnoozeTime,
        hr: value,
      });
    } else {
      setMaxSnoozeTime({
        ...maxSnoozeTime,
        min: value,
      });
    }
  };
  const handleSave = () => {
    setisInitialEntry(false);
    setButtonHit(true);
    const dataToSend = {
      plantId: rxjsState.factoryId,
      configType: 'misc',
      emailId: rxjsState.userData?.email,
      data: [
        {
          parameterName: 'maxSnoozeTime',
          value: parseInt(maxSnoozeTime.hr * 60) + parseInt(maxSnoozeTime.min),
          uom: 'minutes',
        },
      ],
    };
    if (
      !isTimeError(maxSnoozeTime.hr) &&
      !isTimeError(maxSnoozeTime.min) &&
      maxSnoozeTime.hr !== null &&
      maxSnoozeTime.min !== null
    ) {
      postMiscDataMutate(dataToSend);
    }
  };

  return (
    <Box data-testid="container">
      <Grid container>
        <Grid container item sx={12}>
          <HeaderParamGrid item>
            <HeaderTypography>
              {MISCELLANEOUS_CONSTANTS.HEADERS.PARAMETERS}
            </HeaderTypography>
          </HeaderParamGrid>
          <HeaderMsgGrid item>
            <HeaderTypography>
              {MISCELLANEOUS_CONSTANTS.HEADERS.SNOOZE_TIME_DURATION}
            </HeaderTypography>
          </HeaderMsgGrid>
        </Grid>
        <Grid
          container
          item
          sx={{
            backgroundColor: (theme) => theme.customColors.blackGrey,
            alignItems: 'center',
          }}
        >
          <MsgContainerGrid item>
            <Typography
              sx={{
                fontFamily: 'open sans',
                fontWeight: 400,
                fontSize: '.875rem',
                fontStyle: 'normal',
                height: '1.25rem',
                lineHeight: '1.25rem',
                // width: '67.75rem',
              }}
            >
              {MISCELLANEOUS_CONSTANTS.SUB_HEADER_MESSAGE}
            </Typography>
          </MsgContainerGrid>
          <HrMinContainerGrid item container>
            <HourMinInputs
              id="misc"
              hr={maxSnoozeTime.hr}
              min={maxSnoozeTime.min}
              label={MISCELLANEOUS_CONSTANTS.SUB_HEADER_MESSAGE}
              onChange={handleChange}
              isHrError={isTimeError(maxSnoozeTime.hr)}
              isMinError={isTimeError(maxSnoozeTime.min)}
            />
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item sx={{ minWidth: '7rem', maxWidth: '7rem' }}>
                {isTimeError(maxSnoozeTime.hr) && (
                  <ErrorBox
                    maxWidth="4rem"
                    errorMessage={getErrorMessage(maxSnoozeTime.hr)}
                  />
                )}
              </Grid>
              <Grid item sx={{ minWidth: '7rem', maxWidth: '7rem' }}>
                {isTimeError(maxSnoozeTime.min) && (
                  <ErrorBox
                    maxWidth="4rem"
                    errorMessage={getErrorMessage(maxSnoozeTime.min)}
                  />
                )}
              </Grid>
            </Grid>
          </HrMinContainerGrid>
        </Grid>
        <ButtonContainerGrid>
          <GeneralButton
            type={BUTTON_TYPE.PRIMARY}
            text={BUTTON_LABELS.SAVE}
            onClick={handleSave}
            disable={
              isTimeError(maxSnoozeTime.hr) || isTimeError(maxSnoozeTime.min)
            }
          />
        </ButtonContainerGrid>
      </Grid>
    </Box>
  );
};
MiscellaneousSettingsContainer.propTypes = {
  handleStatus: PropTypes.func,
};
export default MiscellaneousSettingsContainer;
