/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

import AccordionList from '../../components/AccordionList';
import SocketConnector from '../../components/SocketConnector';

const StyledTableBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.eerieBlack,
  padding: '0.4rem',
  height: '59.5rem',
}));

export default function EquipmentConstraintsConfigurationContainer() {
  const [isEIPWarningEnabled, setIsEIPWarningEnabled] = useState(false);

  const updateEIPWarningStatus = (status) => {
    setIsEIPWarningEnabled(status);
  };
  return (
    <Box
      sx={{
        margin: '0.75em 1.25em 1.25em',
      }}
    >
      <SocketConnector updateEIPWarningStatus={updateEIPWarningStatus} />
      <Box
        sx={{
          marginBottom: '1.25em',
        }}
      >
        <Typography variant="h6">Equipment Constraints</Typography>
      </Box>
      <StyledTableBox>
        <AccordionList
          updateEIPWarningStatus={updateEIPWarningStatus}
          isEIPWarningEnabled={isEIPWarningEnabled}
        />
      </StyledTableBox>
    </Box>
  );
}
