import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MuiTextField, styled } from '@mui/material';

const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) =>
    prop !== 'disabledgrey' && prop !== 'textalign' && prop !== 'error',
})(({ theme, disabledgrey, textalign, error }) => ({
  '& .Mui-disabled': {
    color: disabledgrey
      ? theme.palette.background.shuttleGray
      : theme.palette.text.primary,
    cursor: 'not-allowed',
  },
  '& label.Mui-focused': {
    color: theme.palette.text.primary,
  },
  '& .MuiOutlinedInput-input': {
    padding: '0.6em 0em 0.6em 0.6em',
    textAlign: textalign,
    fontSize: '0.875rem',
    fontWeight: '400',
    color: theme.palette.text.primary,
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  '& .MuiTypography-colorTextSecondary': {
    color: theme.palette.text.primary,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.blackGrey,
    height: '2em',
    '& fieldset': {
      borderColor: error
        ? theme.palette.border.lavaRed
        : theme.palette.border.lightWhiteGrey,
    },
    '&:hover fieldset': {
      borderColor: error
        ? theme.palette.border.lavaRed
        : theme.palette.border.lightWhiteGrey,
    },
    '&.Mui-focused fieldset': {
      borderColor: error
        ? theme.palette.border.lavaRed
        : theme.palette.border.lightWhiteGrey,
    },
    '&.Mui-disabled fieldset': {
      borderColor: theme.palette.border.lightWhiteGrey,
    },
  },
}));

function GeneralTextField({
  value,
  variant,
  error,
  type,
  onChange,
  maxLen = 5,
  placeholder,
  customInputProps = {},
  fullWidth = true,
  disabledGrey = false,
  textAlign = 'left',
  id,
  ...other
}) {
  return (
    <>
      <StyledTextField
        role="textbox"
        value={value}
        variant={variant || 'outlined'}
        type={type || 'text'}
        onChange={onChange}
        fullWidth={fullWidth}
        className="custom-text-field"
        disabledgrey={disabledGrey}
        textalign={textAlign}
        error={error}
        inputProps={{
          maxLength: maxLen,
          form: {
            autocomplete: 'off',
          },
          ...customInputProps,
        }}
        placeholder={placeholder}
        autoComplete="no"
        data-testid={id ?? 'general-textfield'}
        {...other}
      />
      {/* {true && <ErrorBox errorMessage="value should be a whole number" />} */}
    </>
  );
}

GeneralTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  maxLen: PropTypes.number,
  placeholder: PropTypes.string,
  customInputProps: PropTypes.shape({}),
  fullWidth: PropTypes.bool,
  disabledGrey: PropTypes.bool,
  showErrorIcon: PropTypes.bool,
  textAlign: PropTypes.string,
  id: PropTypes.string,
};

export default GeneralTextField;
