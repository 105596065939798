export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};
export const BUTTON_SIZE = {
  STANDARD: 'standard',
  SMALL: 'small',
};
export const BUTTON_LABELS = {
  SAVE: 'Save',
  NO: 'No',
  YES: 'Yes',
};

export const CONSTRAINT_ACCORDION_TITLES = {
  ACTUALS: 'Actuals',
  TARGETS: 'Targets',
  MISCELLANEOUS_SETTINGS: 'Miscellaneous Settings',
  FAULT_CODE: 'Fault Code',
};

export const DROPDOWN_CONSTANTS = { DEFAULT_VALUE: 'SELECT' };

export const TARGET_CONSTANTS = {
  HEADERS: {
    PARAMETERS: 'Parameters',
    EQUIPMENT_CONSTRAINT: 'Equipment Constraint',
  },
  SUBHEADERS: {
    OPEN_CONSTRAINTS: 'Open Constraints',
    CLOSED_CONSTRAINTS: 'Closed Constraints',
    TOTAL_DURATION: 'Total Duration of Open Constraints',
    AVERAGE_DURATION: 'Average Constraint Resolution Time',
  },
};

export const MISCELLANEOUS_CONSTANTS = {
  HEADERS: {
    PARAMETERS: 'Parameters',
    SNOOZE_TIME_DURATION: 'Snooze Time Duration',
  },
  SUB_HEADER_MESSAGE:
    'Maximum Snooze Time for Manually Closed Equipment Constraints',
};

export const FAULTCODE_CONSTANTS = {
  DOWNLOAD_UPLOAD_TYPE: {
    DOWNLOAD: 'download',
    UPLOAD: 'upload',
  },
};

export const ACTUALS_CONSTANTS = {
  HEADERS: {
    PARAMETERS: 'Parameters',
    VALUE: 'Value',
  },
  SUBHEADERS: {
    TIME_RANGE: 'Time Range',
    REFRESH_RATE: 'Refresh Rate',
  },
};

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

export const ACCORDION_STATUS = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

export const FILE_CONTENT_TYPES = {
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const FILE_UPLOAD_STATUSES = {
  DONE: 'done',
  UPLOADING: 'uploading',
  ABORTED: 'aborted',
  RESTARTED: 'restarted',
  REMOVED: 'removed',
  STARTED: 'started',
  READY: 'ready',
  PREPARING: 'preparing',
  GETTING_UPLOAD_PARAMS: 'getting_upload_params',
  HEADERS_RECEIVED: 'headers_received',
  REJECTED_FILE: 'rejected_file_type',
  UPLOAD_PARAMS_ERROR: 'error_upload_params',
};
