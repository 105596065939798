import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { config, globalstate$ } from '@smf/ui-util-app';

const SocketConnector = ({ updateEIPWarningStatus }) => {
  // ? REGION websocket for notifications
  // #region

  const authToken = globalstate$.value.sessionToken;
  const socketAuthorizationTokens = `${authToken.replace('Bearer ', '')}`;

  const WS_ENDPOINT_EQUIPMENT_CONSTRAINT_CONFIG = `wss://${config.BASE_WS_API_URL}/ecconfig`;

  const ws = useRef();

  const initializeConfigurationDataSocket = {
    action: 'activeConnectionDetails',
    plantId: globalstate$.value.factoryId,
    emailId: globalstate$.value.userData.email,
    authorizationToken: authToken,
  };

  function connectToSocket() {
    ws.current = new WebSocket(
      WS_ENDPOINT_EQUIPMENT_CONSTRAINT_CONFIG,
      socketAuthorizationTokens
    );

    ws.current.onopen = () => {
      // #region // ? trigger socket to get configuration data.
      ws.current.send(JSON.stringify(initializeConfigurationDataSocket));
      // #endregion
    };

    ws.current.onmessage = (evt) => {
      const recvData = JSON.parse(evt.data);

      // #region  // ? SOCKET TYPE HANDLER
      switch (recvData?.type) {
        case 'ecAipUpload':
          updateEIPWarningStatus(recvData.isEquipConstraintAIPUploaded);
          break;
        default:
          break;
      }
      // #endregion
    };
    ws.current.onerror = () => {
      ws.current.close();
    };
    ws.current.onclose = () => {};
  }

  // ? NOTE: Mounting useeffect.
  useEffect(() => {
    connectToSocket();
    return () => {
      ws.current.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  return <div hidden />;
};

export default SocketConnector;

SocketConnector.propTypes = {
  updateEIPWarningStatus: PropTypes.func,
};
