import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Typography,
  Grid,
  MenuItem,
  styled,
  Backdrop,
} from '@mui/material';
import GeneralDropdown from '../../components/GeneralDropdown';
import GeneralTextField from '../../components/GeneralTextField';
import GeneralButton from '../../components/GeneralButton';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
  BUTTON_LABELS,
  BUTTON_TYPE,
  DROPDOWN_CONSTANTS,
  ACTUALS_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
  ACCORDION_STATUS,
  CONSTRAINT_ACCORDION_TITLES,
} from '../../constants';

import {
  queryConstants,
  retrieveActualsData,
  saveActualsData,
} from '../../utils/apihelper';

import ErrorBox from '../../components/ErrorBox';
import { CONSTANT_MESSAGES, ERROR_MESSAGES } from '../../constants/en-us';
import { useRxjsState } from '../../utils/useRxjsState';
import { useToastContext } from '../../context/toastContext';

import PropTypes from 'prop-types';
const ButtonBox = styled(Box)(() => ({
  margin: '0 1rem .625rem 0',
  height: '2.25rem',
}));

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const ActualsContainer = ({
  timerange,
  refreshRateUoMOptions,
  uomAPIData,
  timeRangeAPIData,
  isTimeRangeFetching,
  isUOMDataFetching,
  handleStatus = () => {},
}) => {
  const [timeRangeValue, setTimeRangeValue] = useState(null); // ? shift/production day dropdown value
  const [refreshRateValue, setRefreshRateValue] = useState(null); // ? refresh time range value
  const [refreshRateUoM, setRefreshRateUoM] = useState(); // ? uom for refresh rate
  const [isErrorVisibilityEnabled, setIsErrorVisibilityEnabled] =
    useState(false);
  const [isInitialEntry, setisInitialEntry] = useState(false);

  const { rxjsState } = useRxjsState();
  const { toastDispatch } = useToastContext();
  const queryClient = useQueryClient();

  const { mutate: postActualsDataMutate } = useMutation(
    (data) => saveActualsData(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryConstants.GET_STATUS_DATA);
        handleStatus(
          ACCORDION_STATUS.COMPLETED,
          CONSTRAINT_ACCORDION_TITLES.ACTUALS
        );
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: CONSTANT_MESSAGES.ACTUALS.POST_SUCCESS,
          },
        });
      },
      onError: (error) => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message:
              error?.response?.data?.message ??
              CONSTANT_MESSAGES.DEFAULT_POST_API_ERROR_MESSAGE,
          },
        });
      },
    }
  );

  const { isFetching: isGetActualsFetching } = useQuery(
    [queryConstants.GET_ACTUALS_DATA],
    async () => {
      return await retrieveActualsData({ plantId: rxjsState?.factoryId });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!rxjsState.factoryId && !!uomAPIData && !!timeRangeAPIData,
      onSuccess: (response) => {
        const modifiedResponse = response.response[0];
        setTimeRangeValue(modifiedResponse.timeRange);
        setRefreshRateUoM(modifiedResponse.refreshRateUnit);
        setRefreshRateValue(modifiedResponse.refreshRateValue);
        setisInitialEntry(
          modifiedResponse.refreshRateValue === null ? true : false
        );
      },
    }
  );

  const validateRefreshRateValue = () => {
    // ? only validate if error visibility is enabled.
    if (isErrorVisibilityEnabled) {
      if (refreshRateValue === null && isInitialEntry) {
        return false;
      }

      if (!refreshRateValue) {
        return true;
      }
      if (
        refreshRateValue % 1 !== 0 ||
        (refreshRateUoM === 'seconds' && refreshRateValue < 15) ||
        (refreshRateUoM === 'minutes' && parseInt(refreshRateValue) === 0) ||
        (refreshRateUoM === 'hours' && parseInt(refreshRateValue) === 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const getErrorMessage = () => {
    if (refreshRateValue === null && isInitialEntry) {
      return;
    }
    if (!refreshRateValue) {
      return ERROR_MESSAGES.REQUIRED;
    }
    if (refreshRateValue < 15 && refreshRateUoM === 'seconds') {
      return ERROR_MESSAGES.ACTUALS.LESS_THAN_ERROR;
    }
    if (refreshRateValue % 1 > 0) {
      return ERROR_MESSAGES.NOT_WHOLE_NUMBER;
    }
    return ERROR_MESSAGES.INVALID_VALUE;
  };

  const handleTimeRangeChange = (e) => {
    handleStatus(
      ACCORDION_STATUS.IN_PROGRESS,
      CONSTRAINT_ACCORDION_TITLES.ACTUALS
    );
    setIsErrorVisibilityEnabled(true);
    setTimeRangeValue(e.target.value);
  };

  const handleRefreshRateUoMChange = (e) => {
    // ? clear refresh rate value since the unit has been changed.
    handleStatus(
      ACCORDION_STATUS.IN_PROGRESS,
      CONSTRAINT_ACCORDION_TITLES.ACTUALS
    );
    setIsErrorVisibilityEnabled(true);
    const value = e.target.value;
    setRefreshRateUoM(value);
    setRefreshRateValue(null);
  };
  const handleRefreshRateValueChange = (e) => {
    handleStatus(
      ACCORDION_STATUS.IN_PROGRESS,
      CONSTRAINT_ACCORDION_TITLES.ACTUALS
    );
    setIsErrorVisibilityEnabled(true);
    let value = e.target.value;
    // ? limiting seconds and minutes. hours doesn't have an upper limit.
    if (
      (refreshRateUoM === 'seconds' && value > 59) ||
      (refreshRateUoM === 'minutes' && value > 59)
    ) {
      return;
    }
    if (value >= 0 && value <= 99999 && value.length < 6) {
      setRefreshRateValue(value);
    }
  };
  const handleOnSaveClick = () => {
    setIsErrorVisibilityEnabled(true);
    setisInitialEntry(false);
    // ? if there is any error OR if the errors are not displayed yet, dont' trigger the post api.
    if (validateRefreshRateValue() || !isErrorVisibilityEnabled) {
      return;
    }
    if (
      refreshRateValue === null ||
      refreshRateUoM === null ||
      timeRangeValue === null
    ) {
      return;
    }

    const dataToSend = {
      entityId: rxjsState.factoryId,
      refreshRateValue: parseInt(refreshRateValue),
      refreshRateUnit: refreshRateUoM,
      parameterType: 'EquipmentConstraint',
      timeRangeValue: timeRangeValue,
      emailId: rxjsState.userData?.email,
    };

    postActualsDataMutate(dataToSend);
  };

  return (
    <>
      <Box
        data-testid="container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/*Header*/}
        <Grid
          container
          item
          sx={{
            gridArea: 'header',
            backgroundColor: (theme) => theme.customColors.darkishBlackBlue,
            height: '3.5rem',
            alignItems: 'center',
            color: (theme) => theme.customColors.lightUnitGrey,
            border: '1px solid rgba(83, 86, 90, 0.3)',
          }}
        >
          <Grid
            item
            sx={{
              width: '45%',
              paddingLeft: '1.25rem',
            }}
          >
            <Typography variant="subtitle2">
              {ACTUALS_CONSTANTS.HEADERS.PARAMETERS}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              {ACTUALS_CONSTANTS.HEADERS.VALUE}
            </Typography>
          </Grid>
        </Grid>
        {/*Time Range*/}
        <Grid
          container
          item
          sx={{
            backgroundColor: (theme) => theme.customColors.blackGrey,
            maxHeight: '6rem',
            paddingTop: '.5rem',
            paddingBottom: '.5rem',
            border: '1px solid rgba(83, 86, 90, 0.3)',
            alignItems: 'center',
          }}
        >
          <Grid
            item
            sx={{
              width: '45%',
              paddingLeft: '1.25rem',
            }}
          >
            <Typography variant="subtitle2">
              {ACTUALS_CONSTANTS.SUBHEADERS.TIME_RANGE}
            </Typography>
          </Grid>
          <Grid item>
            <GeneralDropdown
              id="time-range-dropdown"
              value={timeRangeValue || DROPDOWN_CONSTANTS.DEFAULT_VALUE}
              onChange={handleTimeRangeChange}
              error={
                timeRangeValue === null &&
                !isInitialEntry &&
                isErrorVisibilityEnabled
              }
              sx={{ width: '10rem !important' }}
              placeholderText="Select"
            >
              {timerange.map((item) => (
                <MenuItem key={item.label} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </GeneralDropdown>
            {timeRangeValue === null &&
              !isInitialEntry &&
              isErrorVisibilityEnabled && (
                <Grid item>
                  <ErrorBox errorMessage={ERROR_MESSAGES.REQUIRED} />
                </Grid>
              )}
          </Grid>
        </Grid>
        {/*Refresh Rate*/}
        <Grid
          container
          item
          sx={{
            backgroundColor: (theme) => theme.customColors.blackGrey,
            height: '100%',
            padding: '0.5rem 0',
            border: '1px solid rgba(83, 86, 90, 0.3)',
            alignItems: 'flex-start',
          }}
        >
          <Grid
            item
            sx={{
              width: '45%',
              paddingLeft: '1.25rem',
            }}
          >
            <Typography variant="subtitle2">
              {ACTUALS_CONSTANTS.SUBHEADERS.REFRESH_RATE}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="flex-start"
            sx={{ width: '10rem' }}
          >
            <Grid item>
              <GeneralTextField
                id="refresh_rate"
                placeholder="-"
                value={refreshRateValue === null ? '' : refreshRateValue}
                onChange={handleRefreshRateValueChange}
                error={validateRefreshRateValue()}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                maxLen={255}
                sx={{ width: '10rem !important' }}
              />
            </Grid>
            {validateRefreshRateValue() && (
              <Grid item>
                <ErrorBox errorMessage={getErrorMessage()} />
              </Grid>
            )}
          </Grid>

          <Grid item>
            <GeneralDropdown
              id="refresh-rate-dropdown"
              value={refreshRateUoM || DROPDOWN_CONSTANTS.DEFAULT_VALUE}
              onChange={handleRefreshRateUoMChange}
              error={
                refreshRateUoM === null &&
                !isInitialEntry &&
                isErrorVisibilityEnabled
              }
              sx={{
                width: '10rem !important',
                marginLeft: '0.5rem',
              }}
              placeholderText="Select"
            >
              {refreshRateUoMOptions.map((item) => (
                <MenuItem key={item.label} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </GeneralDropdown>
            {refreshRateUoM === null &&
              !isInitialEntry &&
              isErrorVisibilityEnabled && (
                <Grid item sx={{ marginLeft: '0.5rem' }}>
                  <ErrorBox errorMessage={ERROR_MESSAGES.REQUIRED} />
                </Grid>
              )}
          </Grid>
        </Grid>

        <Grid
          container
          item
          sx={{
            height: '3.5rem',
            alignItems: 'center',
            flexDirection: 'row-reverse',
            paddingRight: '1rem',
          }}
        >
          <ButtonBox>
            <GeneralButton
              type={BUTTON_TYPE.PRIMARY}
              text={BUTTON_LABELS.SAVE}
              onClick={handleOnSaveClick}
              disable={validateRefreshRateValue()}
            />
          </ButtonBox>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', maxHeight: '100%', flexDirection: 'column' }}>
        <CustomBackdrop
          open={
            isGetActualsFetching || isTimeRangeFetching || isUOMDataFetching
          }
        >
          <LoadingIndicator />
        </CustomBackdrop>
      </Box>
    </>
  );
};
ActualsContainer.propTypes = {
  handleStatus: PropTypes.func,
  timerange: PropTypes.oneOfType([PropTypes.shape(), PropTypes.array]),
  refreshRateUoMOptions: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.array,
  ]),
  uomAPIData: PropTypes.shape(),
  timeRangeAPIData: PropTypes.oneOfType([PropTypes.shape(), PropTypes.array]),
  isTimeRangeFetching: PropTypes.bool,
  isUOMDataFetching: PropTypes.bool,
};
export default ActualsContainer;
