import {
  ACCORDION_STATUS,
  CONSTRAINT_ACCORDION_TITLES,
  TOAST_REDUCER_CONSTANTS,
} from '../constants';
import { CONSTANT_MESSAGES } from '../constants/en-us';
import { queryConstants } from './apihelper';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const convertCase = (status) => {
  return status
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/(?: |\b)(\w)/g, function (key) {
      return key.toUpperCase();
    });
};

export const formatUploadInformation = (response) => {
  return {
    firstName: response?.firstName ?? 'N/A',
    lastName: response?.lastName ?? 'N/A',
    date: response?.uploadDate ?? 'N/A',
    time: response?.uploadTime ?? 'N/A',
    timezone: response?.timeZone ?? 'N/A',
    isUploaded: response?.isUploaded ?? false,
    fileName: response?.fileName ?? 'N/A',
    fileSize: response?.fileSize ?? 0,
  };
};

export const getParamsToUploadToWebsocket = (
  rxjsState,
  fileName,
  authToken
) => {
  return {
    plantId: rxjsState.factoryId,
    fileName: fileName,
    action: 'uploadFaultCode',
    firstName: rxjsState.userData.firstName,
    lastName: rxjsState.userData.lastName,
    emailId: rxjsState.userData.email,
    authorizationToken: authToken,
  };
};

export const handleWebsocketUploadError = (
  error,
  handleStatus,
  setErrorlist,
  setWarnList,
  queryClient,
  toastDispatch
) => {
  if (error.warningMsgs) {
    const listOfWarnings = error.warningMsgs;
    setWarnList(listOfWarnings);
  }
  if (error.isError) {
    const listOfErrors = error.detailedMessage;
    handleStatus(
      ACCORDION_STATUS.IN_PROGRESS,
      CONSTRAINT_ACCORDION_TITLES.FAULT_CODE
    );
    setErrorlist(listOfErrors);
  } else {
    // ? suceess. get the data to display in the box.
    queryClient.invalidateQueries(queryConstants.GET_STATUS_DATA);
    toastDispatch({
      type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
      payload: {
        message: CONSTANT_MESSAGES.FAULT_CODE.FILE_UPLOAD_SUCCESS,
      },
    });

    queryClient.invalidateQueries(queryConstants.GET_USER_DETAILS);
    setErrorlist([]);
    handleStatus(
      ACCORDION_STATUS.COMPLETED,
      CONSTRAINT_ACCORDION_TITLES.FAULT_CODE
    );
  }
};
