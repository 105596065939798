/* eslint-disable no-warning-comments */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, styled } from '@mui/material';

import AccordionStatus from '../AccordionStatus';
import CustomizedAccordions from '../Accordion';
import ActualsContainer from '../../containers/ActualsContainer';
import TargetsContainer from '../../containers/TargetsContainer';
import MiscellaneousSettingsContainer from '../../containers/MiscellaneousSettingsContainer';
import FaultCodesContainer from '../../containers/FaultCodesContainer';
import AlertBox from '../AlertBox';

import { ACCORDION_STATUS, CONSTRAINT_ACCORDION_TITLES } from '../../constants';

import { ReactComponent as DownArrow } from '../../assets/img/downArrow.svg';

import { useQuery } from 'react-query';
import { useRxjsState } from '../../utils/useRxjsState';
import {
  queryConstants,
  retrieveConfigStatusData,
  retrieveTimeRangeData,
  retrieveUOMData,
} from '../../utils/apihelper';
import { updateConstraintsConfigStatus } from '../../utils/rxjsHelpers';
import { CONSTANT_MESSAGES } from '../../constants/en-us';
import { capitalizeFirstLetter } from '../../utils/helpers';

const AccordionTitleTypography = styled(Typography)(() => ({
  fontWeight: '700 ',
  fontSize: '0.875rem ',
  lineHeight: '1.192rem ',
}));

const AccordionList = ({ updateEIPWarningStatus, isEIPWarningEnabled }) => {
  const [currentExpandedAccordion, setcurrentExpandedAccordion] = useState(
    CONSTRAINT_ACCORDION_TITLES.ACTUALS
  );

  const [statusMisc, setStatusMisc] = useState(ACCORDION_STATUS.NOT_STARTED);
  const [statusFault, setStatusFault] = useState(ACCORDION_STATUS.NOT_STARTED);

  const [refreshRateUoMOptions, setRefreshRateUoMOptions] = useState([]); // ? available options for refreshrate uom

  const [timerange, setTimerangeOptions] = useState([]); // ? available options for refreshrate uom

  const [statusActuals, setStatusActuals] = useState(
    ACCORDION_STATUS.NOT_STARTED
  );
  const [statusTargets, setStatusTargets] = useState(
    ACCORDION_STATUS.NOT_STARTED
  );

  useEffect(() => {
    // ? update the constraints configuration side bar status whenever the local states change (in progress).
    updateConstraintsConfigStatus(
      !!(
        statusActuals === ACCORDION_STATUS.COMPLETED &&
        statusFault === ACCORDION_STATUS.COMPLETED &&
        statusMisc === ACCORDION_STATUS.COMPLETED &&
        statusFault === ACCORDION_STATUS.COMPLETED &&
        !isEIPWarningEnabled
      )
    );
  }, [
    statusActuals,
    statusTargets,
    statusMisc,
    statusFault,
    isEIPWarningEnabled,
  ]);

  const { rxjsState } = useRxjsState();
  useQuery(
    [queryConstants.GET_STATUS_DATA],
    async () => {
      return await retrieveConfigStatusData({ plantId: rxjsState.factoryId });
    },
    {
      enabled: !!rxjsState.factoryId,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        // ? update status initially and whenever a dave occurs.
        updateConstraintsConfigStatus(response.equipmentConstraint);
        setStatusActuals(ACCORDION_STATUS[response.actualStatus]);
        setStatusTargets(ACCORDION_STATUS[response.targetStatus]);
        setStatusMisc(ACCORDION_STATUS[response.miscStatus]);
        setStatusFault(
          response.faultCodeStatus
            ? ACCORDION_STATUS[response.faultCodeStatus]
            : ACCORDION_STATUS.NOT_STARTED
        );
        updateEIPWarningStatus(response.isEquipConstraintAIPUploaded ?? false);
      },
    }
  );

  const { data: uomAPIData, isFetching: isUOMDataFetching } = useQuery(
    [queryConstants.GET_UOM],
    async () => {
      return await retrieveUOMData();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const modifiedResponse = [];
        response.measurementUnits.forEach((item) => {
          modifiedResponse.push({
            label: capitalizeFirstLetter(item),
            value: item,
          });
        });

        setRefreshRateUoMOptions(modifiedResponse);
      },
    }
  );

  const { data: timeRangeAPIData, isFetching: isTimeRangeFetching } = useQuery(
    [queryConstants.GET_TIME_RANGE],
    async () => {
      return await retrieveTimeRangeData();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const modifiedResponse = [];
        response.timeRange.map((item) => {
          modifiedResponse.push({
            label: item,
            value: item,
          });
        });
        setTimerangeOptions(modifiedResponse);
      },
    }
  );

  const handleStatus = (status, accordian) => {
    switch (accordian) {
      case CONSTRAINT_ACCORDION_TITLES.ACTUALS:
        setStatusActuals(status);
        break;
      case CONSTRAINT_ACCORDION_TITLES.TARGETS:
        setStatusTargets(status);
        break;
      case CONSTRAINT_ACCORDION_TITLES.MISCELLANEOUS_SETTINGS:
        setStatusMisc(status);
        break;
      case CONSTRAINT_ACCORDION_TITLES.FAULT_CODE:
        setStatusFault(status);
        break;
      default:
        return;
    }
  };

  return (
    <>
      <Box sx={{ marginBottom: '1rem' }} data-testid="accordion-list-container">
        <CustomizedAccordions
          data-testid="actuals-accordion"
          Accordian
          id="constraints_config_Accordions_actuals"
          expanded={
            currentExpandedAccordion === CONSTRAINT_ACCORDION_TITLES.ACTUALS
          }
          onChange={() => {
            setcurrentExpandedAccordion(CONSTRAINT_ACCORDION_TITLES.ACTUALS);
          }}
        >
          {/* // ? accordion Actuals */}
          <CustomizedAccordions.AccordionSummary
            aria-controls="=deviations-content"
            id="deviation-threshold-content"
            expandIcon={<DownArrow height="9" width="16" />}
          >
            <AccordionTitleTypography variant="h5">
              {CONSTRAINT_ACCORDION_TITLES.ACTUALS}
            </AccordionTitleTypography>
            <AccordionStatus status={statusActuals} />
          </CustomizedAccordions.AccordionSummary>
          <CustomizedAccordions.AccordionDetails>
            <ActualsContainer
              timerange={timerange}
              refreshRateUoMOptions={refreshRateUoMOptions}
              uomAPIData={uomAPIData}
              timeRangeAPIData={timeRangeAPIData}
              isTimeRangeFetching={isTimeRangeFetching}
              isUOMDataFetching={isUOMDataFetching}
              handleStatus={handleStatus}
            />
          </CustomizedAccordions.AccordionDetails>
        </CustomizedAccordions>
        <CustomizedAccordions
          data-testid="targets-accordion"
          Accordian
          id="constraints_config_Accordions_targets"
          expanded={
            currentExpandedAccordion === CONSTRAINT_ACCORDION_TITLES.TARGETS
          }
          onChange={() => {
            setcurrentExpandedAccordion(CONSTRAINT_ACCORDION_TITLES.TARGETS);
          }}
        >
          {/* // ? accordion Targets */}
          <CustomizedAccordions.AccordionSummary
            aria-controls="=deviations-content"
            id="deviation-threshold-content"
            expandIcon={<DownArrow height="9" width="16" />}
          >
            <AccordionTitleTypography variant="h5">
              {CONSTRAINT_ACCORDION_TITLES.TARGETS}
            </AccordionTitleTypography>
            <AccordionStatus status={statusTargets} />
          </CustomizedAccordions.AccordionSummary>
          <CustomizedAccordions.AccordionDetails>
            <TargetsContainer handleStatus={handleStatus} />
          </CustomizedAccordions.AccordionDetails>
        </CustomizedAccordions>
        <CustomizedAccordions
          data-testid="misc-accordion"
          Accordian
          id="constraints_config_Accordions_misc"
          expanded={
            currentExpandedAccordion ===
            CONSTRAINT_ACCORDION_TITLES.MISCELLANEOUS_SETTINGS
          }
          onChange={() => {
            setcurrentExpandedAccordion(
              CONSTRAINT_ACCORDION_TITLES.MISCELLANEOUS_SETTINGS
            );
          }}
        >
          {/* // ? accordion Miscellaneous Settings */}
          <CustomizedAccordions.AccordionSummary
            aria-controls="=deviations-content"
            id="deviation-threshold-content"
            expandIcon={<DownArrow height="9" width="16" />}
          >
            <AccordionTitleTypography variant="h5">
              {CONSTRAINT_ACCORDION_TITLES.MISCELLANEOUS_SETTINGS}
            </AccordionTitleTypography>
            <AccordionStatus status={statusMisc} />
          </CustomizedAccordions.AccordionSummary>
          <CustomizedAccordions.AccordionDetails>
            <MiscellaneousSettingsContainer handleStatus={handleStatus} />
          </CustomizedAccordions.AccordionDetails>
        </CustomizedAccordions>
        <CustomizedAccordions
          data-testid="faultcode-accordion"
          Accordian
          id="constraints_config_Accordions_fault_code"
          expanded={
            currentExpandedAccordion === CONSTRAINT_ACCORDION_TITLES.FAULT_CODE
          }
          onChange={() => {
            setcurrentExpandedAccordion(CONSTRAINT_ACCORDION_TITLES.FAULT_CODE);
          }}
        >
          {/* // ? accordion Fault code */}
          <CustomizedAccordions.AccordionSummary
            aria-controls="=deviations-content"
            id="deviation-threshold-content"
            expandIcon={<DownArrow height="9" width="16" />}
          >
            <AccordionTitleTypography variant="h5">
              {CONSTRAINT_ACCORDION_TITLES.FAULT_CODE}
            </AccordionTitleTypography>
            {isEIPWarningEnabled && (
              <AlertBox
                width={24}
                height={24}
                message={CONSTANT_MESSAGES.EIP_WARNING_MESSAGE}
                styleProps={{ marginLeft: '3.25rem' }}
              />
            )}
            <AccordionStatus
              status={
                isEIPWarningEnabled ? ACCORDION_STATUS.IN_PROGRESS : statusFault
              }
            />
          </CustomizedAccordions.AccordionSummary>
          <CustomizedAccordions.AccordionDetails>
            <FaultCodesContainer
              handleStatus={handleStatus}
              statusFault={statusFault}
            />
          </CustomizedAccordions.AccordionDetails>
        </CustomizedAccordions>
      </Box>
    </>
  );
};

export default AccordionList;

AccordionList.propTypes = {
  updateEIPWarningStatus: PropTypes.func,
  isEIPWarningEnabled: PropTypes.bool,
};
