import React from 'react';

import PropTypes from 'prop-types';

import { Grid, Typography, styled } from '@mui/material';
import GeneralTextField from '../GeneralTextField';

const LowerCaseTypography = styled(Typography)(() => ({
  textTransform: 'lowercase',
}));

const HourMinInputs = ({
  onChange = () => {},
  label = '',
  hr,
  min,
  isHrError,
  isMinError,
  id,
}) => {
  const handleChange = (e, isHour) => {
    if (
      isHour &&
      e.target.value >= 0 &&
      e.target.value <= 99999 &&
      e.target.value.length < 6
    ) {
      onChange(e, label, true);
    } else if (!isHour && e.target.value >= 0 && e.target.value <= 59) {
      onChange(e, label, false);
    }
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      data-testid="hour-min-component"
    >
      <Grid item sx={{ width: '2.875rem', marginRight: '1rem' }}>
        <GeneralTextField
          key="hour"
          placeholder="-"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value={hr}
          onChange={(e) => handleChange(e, true)}
          error={isHrError}
          id={id ? id + '-hour-component' : 'hour-component'}
        />
      </Grid>
      <Grid item sx={{ marginRight: '2.3125rem', marginTop: '.625rem' }}>
        <LowerCaseTypography variant="subtitle2">hr</LowerCaseTypography>
      </Grid>
      <Grid item sx={{ width: '2.875rem', marginRight: '1rem' }}>
        <GeneralTextField
          key="minutes"
          placeholder="-"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value={min}
          onChange={(e) => handleChange(e, false)}
          error={isMinError}
          id={id ? id + '-min-component' : 'min-component'}
        />
      </Grid>
      <Grid sx={{ marginTop: '.625rem' }}>
        <LowerCaseTypography variant="subtitle2">min</LowerCaseTypography>
      </Grid>
    </Grid>
  );
};

export default HourMinInputs;

HourMinInputs.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  hr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isHrError: PropTypes.bool,
  isMinError: PropTypes.bool,
};
