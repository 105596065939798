import { ThemeProvider } from '@mui/material/styles';
import ReactQueryProvider from './context/react-query.provider';

import { theme } from '@smf/ui-util-app';
import EquipmentConstraintsConfigurationContainer from './containers/EquipmentConstraintsConfigurationContainer';
import { ToastProvider } from './context/toastContext';

// eslint-disable-next-line no-unused-vars
export default function Root(props) {
  return (
    <ThemeProvider theme={theme}>
      <ReactQueryProvider>
        <ToastProvider>
          <EquipmentConstraintsConfigurationContainer />
        </ToastProvider>
      </ReactQueryProvider>
    </ThemeProvider>
  );
}
