import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import download from '../../assets/img/download.svg';
import whiteUploadIcon from '../../assets/img/whiteUploadIcon.svg';
// eslint-disable-next-line react/prop-types
const ExportImportDataButton = ({ type, id, text, onClick = () => {} }) => {
  return (
    <Grid
      container
      direction="row"
      space={1}
      sx={{ cursor: 'pointer' }}
      onClick={onClick}
      data-testid={id ?? 'upload-download-component'}
    >
      <Grid
        item
        sx={{ padding: '0rem .250rem 0rem .250rem' }}
        data-testid="upload-download-icon"
      >
        {type === 'download' ? (
          <img src={download} alt="icons" />
        ) : (
          <img src={whiteUploadIcon} alt="icons" />
        )}
      </Grid>
      <Grid item sx={{ padding: '0rem .250rem 0rem .250rem' }}>
        <Typography
          data-testid="upload-download-text"
          sx={{
            fontFamily: 'open sans',
            fontWeight: 400,
            fontSize: '1rem',
            fontStyle: 'normal',
            lineHeight: '2.2rem',
          }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};
ExportImportDataButton.prototype = {
  type: PropTypes.string,
  text: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
};
export default ExportImportDataButton;
