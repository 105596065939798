import { globalstate$, config } from '@smf/ui-util-app';

const authToken = globalstate$.value.sessionToken;
const socketAuthorizationTokens = `${authToken.replace('Bearer ', '')}`;

const WS_ENDPOINT_EQUIPMENT_CONSTRAINT_CONFIG = `wss://${config.BASE_WS_API_URL}/ecconfig`;

export default function faultCodeUploadWebSocket(dataToSend) {
  return new Promise((reject, resolve) => {
    const ws = new WebSocket(
      WS_ENDPOINT_EQUIPMENT_CONSTRAINT_CONFIG,
      socketAuthorizationTokens
    );
    // console.log(WS_ENDPOINT_EQUIPMENT_CONSTRAINT_CONFIG, 'Data');
    ws.onopen = () => {
      if (dataToSend !== null) {
        ws.send(JSON.stringify({ action: 'uploadFaultCode', ...dataToSend }));
      }
    };
    ws.onmessage = ({ data }) => {
      const recievedData = JSON.parse(data);
      if (
        recievedData.isError !== undefined ||
        recievedData.isError === false
      ) {
        ws.close();
        resolve(recievedData);
      } else {
        ws.close();
        reject(recievedData);
      }
      // ws.close();
    };
    ws.onerror = (err) => {
      // console.log('err', err);
      ws.close();
      reject(err);
    };
  });
}
