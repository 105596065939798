import React from 'react';
import { Button as MuiButton, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../constants';

const PrimaryButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'type' && prop !== 'buttonSize',
})(({ theme, buttonSize }) => {
  const isStandard = buttonSize === BUTTON_SIZE.STANDARD;
  return {
    margin: theme.spacing(0.5),
    color: `${theme.palette.text.secondary} `,
    border: `0.0625em solid ${theme.palette.background.saveGreen}`,
    boxSizing: 'border-box',
    borderRadius: '1.25rem',
    minWidth: '7.5em ',
    height: '100%',

    //height: isStandard ? '2.25rem' : '1.5rem',
    //minWidth: isStandard ? '6.5rem' : '3rem',
    fontSize: isStandard ? '.875rem' : '.75rem',

    background: `${theme.palette.background.saveGreen} `,
    '&:hover': {
      color: `${theme.palette.text.primary} `,
      background: 'inherit',
    },
    '&:disabled': {
      color: `${theme.palette.text.lightYellowishGray} `,
      background: `${theme.palette.background.darkGrey} `,
      border: `0.0625em solid ${theme.palette.border.lightRaven}`,
    },
  };
});

const SecondaryButton = styled(MuiButton)(({ theme, buttonSize }) => {
  const isStandard = buttonSize === BUTTON_SIZE.STANDARD;
  return {
    margin: theme.spacing(0.5),
    color: theme.palette.text.primary,
    border: `0.0625em solid ${theme.palette.text.primary}`,
    boxSizing: 'border-box',
    borderRadius: '1.25rem',
    minWidth: '7.5em',
    background: 'inherit',
    height: '100%',

    //height: isStandard ? '2.25rem' : '1.5rem',
    //minWidth: isStandard ? '6.5rem' : '3rem',
    fontSize: isStandard ? '.875rem' : '.75rem',

    '&:hover': {
      color: theme.palette.text.secondary,
      background: theme.palette.text.primary,
    },
    '&:disabled': {
      color: theme.palette.text.darkGrey,
      background: 'transparent',
      border: `0.0625em solid ${theme.palette.border.darkGrey}`,
    },
  };
});

const ButtonLabel = styled(Typography)(() => ({
  textTransform: 'none',
}));

function GeneralButton({
  type = BUTTON_TYPE.PRIMARY,
  text,
  size,
  color,
  variant,
  onClick,
  disable,
  children,
  buttonSize = BUTTON_SIZE.STANDARD,
  ...other
}) {
  return type === BUTTON_TYPE.SECONDARY ? (
    <SecondaryButton
      variant={variant || 'contained'}
      size={size || 'medium'}
      color={color || 'primary'}
      onClick={onClick}
      disabled={disable || false}
      buttonSize={buttonSize}
      data-testid="general-secondary-button"
      {...other}
    >
      {children || <ButtonLabel variant="subtitle1">{text}</ButtonLabel>}
    </SecondaryButton>
  ) : (
    <PrimaryButton
      variant={variant || 'contained'}
      size={size || 'medium'}
      color={color || 'primary'}
      onClick={onClick}
      disabled={disable || false}
      buttonSize={buttonSize}
      data-testid="general-primary-button"
      {...other}
    >
      {children || <ButtonLabel variant="subtitle1">{text}</ButtonLabel>}
    </PrimaryButton>
  );
}

GeneralButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  disable: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonSize: PropTypes.string,
};

export default GeneralButton;
