import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StatusTypography = styled(Typography)(() => ({
  fontWeight: '400',
  fontSize: '0.875rem',
  lineHeight: '1.188rem',
  fontStyle: 'normal',
}));

const StyledAccordionStatusBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.background.gunmetalGrey,
  justifyContent: 'center',
  borderRadius: '0.25rem',
  minWidth: '5.8rem',
  marginLeft: 'auto',
}));

const AccordionStatus = ({ status }) => {
  return (
    <StyledAccordionStatusBox data-testid="acc-status">
      <StatusTypography>{status}</StatusTypography>
    </StyledAccordionStatusBox>
  );
};
AccordionStatus.propTypes = {
  status: PropTypes.string,
};
export default AccordionStatus;
