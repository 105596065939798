/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  ACCORDION_STATUS,
  FILE_CONTENT_TYPES,
  FILE_UPLOAD_STATUSES,
  CONSTRAINT_ACCORDION_TITLES,
} from '../../constants/index';
import { Box, Typography, styled, LinearProgress } from '@mui/material';
// import { convertCase } from '../../utils/helpers';
import Dropzone from 'react-dropzone-uploader';
import UploadInput from '../UploadInput';
import UploadPreview from '../UploadPreview';
import { globalstate$ } from '@smf/ui-util-app';
import { retrieveUploadPresignedURL } from '../../utils/apihelper';
import { useRxjsState } from '../../utils/useRxjsState';
import { formatBytes } from '../../utils/helpers';

import xlsxIcon from '../../assets/img/xlsxIcon.svg';
import greenTick from '../../assets/img/greenTick.svg';
import greenUploadIcon from '../../assets/img/greenUploadIcon.svg';
import exclamationMark from '../../assets/img/exclamationMark.svg';

const UserDataTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.lightUnitGrey,
  fontSize: '.875rem',
  fontWeight: '400',
  fontStyle: 'normal',
  fontFamily: 'open sans',
  lineHeight: '1.1875rem',
}));

const ProgressContainer = styled(Box)(({ theme }) => ({
  width: '25.625rem',
  backgroundColor: theme.palette.background.elevationGrey,
  padding: '0.75rem',
  boxShadow: '0 0.25em 0.25em rgba(0, 0, 0, 0.25)',
  borderRadius: '0.25rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '0.438rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.customColors.progressBarEmpty,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.customColors.progressBarBlue,
  },
}));

const SmallUploadIcon = styled('div')(() => ({
  height: '3.125rem',
  width: '3.125rem',
}));

const FileSizeTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '1.362rem',
  color: theme.palette.text.blockGrey,
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: '1rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '1.362rem',
  marginTop: '0.625rem',
}));

const UploadBox = forwardRef(
  (
    {
      isValidateError,
      validate,
      handleStatus,
      userData,
      isFileUploadedWithoutErrors,
      setModalOpenStatus,
      errorList,
      statusFault,
      isUploadSocketError,
    },
    ref
  ) => {
    const [metaFileReupload, setMetaFileReupload] = useState([]);

    const { rxjsState } = useRxjsState();
    const getUploadUrl = async (file) => {
      const params = {
        plantId: rxjsState.factoryId,
        fileName: file,
      };
      const result = await retrieveUploadPresignedURL(params);
      return result.data;
    };

    const getUploadParams = async (params) => {
      const { file, meta } = params;
      const uploadUrl = await getUploadUrl(meta.name);
      return {
        body: file,
        url: uploadUrl,
        meta,
        method: 'PUT',
        headers: {
          'Content-Type': FILE_CONTENT_TYPES.XLSX,
        },
      };
    };

    const handleChangeStatus = (fileDetails, status) => {
      // ? close the reupload modal in case it's open
      setModalOpenStatus(false);

      if (
        status === FILE_UPLOAD_STATUSES.PREPARING ||
        status === FILE_UPLOAD_STATUSES.GETTING_UPLOAD_PARAMS ||
        status === FILE_UPLOAD_STATUSES.UPLOADING
      ) {
        handleStatus(
          ACCORDION_STATUS.IN_PROGRESS,
          CONSTRAINT_ACCORDION_TITLES.FAULT_CODE
        );
      }
      if (status === FILE_UPLOAD_STATUSES.DONE) {
        validate(fileDetails.meta.name);
      }
    };
    const handleReupload = (fileWithMeta) => {
      if (ref?.current) {
        ref.current.click();
      }
    };
    return (
      <Box
        sx={{
          height: '32.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box>
          {/* // ? displayed if no file has been uploaded */}
          {!userData?.isUploaded && statusFault !== ACCORDION_STATUS.COMPLETED && (
            <Box
              hidden={userData?.isUploaded}
              data-testid="primary_upload"
              id="primary upload"
            >
              <Dropzone
                data-testid="primary_upload_dropzone"
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                SubmitButtonComponent={() => null}
                PreviewComponent={(previewProps) => (
                  <UploadPreview
                    previewComponentProps={{
                      ...previewProps,
                      meta: {
                        name:
                          metaFileReupload.length > 0
                            ? metaFileReupload[0]?.name
                            : previewProps.meta.name,
                        size:
                          metaFileReupload.length > 0
                            ? metaFileReupload[0]?.size
                            : previewProps.meta.size,
                      },
                    }}
                    statusFault={statusFault}
                    errorList={errorList}
                    handleReupload={handleReupload}
                    isFileUploadedWithoutErrors={isFileUploadedWithoutErrors}
                    isFileUploaded={userData?.isUploaded}
                    isUploadSocketError={isUploadSocketError}
                  />
                )}
                InputComponent={(inputComponentProps) => {
                  return userData?.isUploaded ? null : (
                    <UploadInput
                      id="primary_input"
                      isReUpload={false}
                      ref={ref}
                      setMetaFileReupload={setMetaFileReupload}
                      {...inputComponentProps}
                    />
                  );
                }}
                accept={FILE_CONTENT_TYPES.XLSX}
                multiple={false}
                canRestart
              />
            </Box>
          )}

          {/* // ? displayed if there are any validation errors */}
          {/* // NOTE: Writing another dropzone for reupload purpose with useRef concepts across different components */}
          {isValidateError && (
            <Box
              hidden={true}
              id="secondary upload"
              data-testid="secondary_upload"
            >
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                SubmitButtonComponent={() => null}
                PreviewComponent={(previewProps) => (
                  <UploadPreview
                    previewComponentProps={{
                      ...previewProps,
                      meta: {
                        name:
                          metaFileReupload.length > 0
                            ? metaFileReupload[0]?.name
                            : previewProps.meta.name,
                        size:
                          metaFileReupload.length > 0
                            ? metaFileReupload[0]?.size
                            : previewProps.meta.size,
                      },
                    }}
                    statusFault={statusFault}
                    errorList={errorList}
                    handleReupload={handleReupload}
                    isFileUploadedWithoutErrors={isFileUploadedWithoutErrors}
                    isFileUploaded={userData?.isUploaded}
                    isUploadSocketError={isUploadSocketError}
                  />
                )}
                InputComponent={(inputComponentProps) => {
                  return userData?.isUploaded ? null : (
                    <UploadInput
                      id="secondary_input"
                      ref={ref}
                      isReUpload={true}
                      setMetaFileReupload={setMetaFileReupload}
                      {...inputComponentProps}
                    />
                  );
                }}
                accept={FILE_CONTENT_TYPES.XLSX}
                multiple={false}
                canRestart
              />
            </Box>
          )}

          {/* // ? displyed after file is successfully uploaded. */}
          {/* // ? we will use this to trigger the reupload after success as well. */}
          {isFileUploadedWithoutErrors && (
            <>
              <Box
                id="success_box"
                data-testid="success_box"
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <div>
                  <ProgressContainer data-testid="progresss-container_success">
                    <div style={{ flexGrow: '0.2' }}>
                      <img src={xlsxIcon} alt="xlsx-icon" />
                    </div>
                    <Box
                      sx={{
                        flexGrow: '1',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginLeft: '2rem',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <div>{userData?.fileName}</div>
                      </Box>
                      <FileSizeTypography>
                        {formatBytes(userData?.fileSize)}
                      </FileSizeTypography>
                      <StyledLinearProgress
                        variant="determinate"
                        value={Math.round(100)}
                      />
                    </Box>
                    {!userData?.isUploaded && (
                      <Box sx={{ flexGrow: '0.2', marginLeft: '2.125rem' }}>
                        <img
                          src={exclamationMark}
                          alt="error-icon"
                          data-testid="exclamation_icon"
                        />
                      </Box>
                    )}
                    {userData?.isUploaded && (
                      <Box sx={{ flexGrow: '0.2', marginLeft: '2.125rem' }}>
                        <img
                          src={greenTick}
                          alt="error-icon"
                          data-testid="tick_icon_success"
                        />
                      </Box>
                    )}
                  </ProgressContainer>
                </div>
              </Box>
            </>
          )}

          {/* // ? this is the preview component for the success reupload UI. should only be displayed if the file is 
          // ?   being reuploaded and if the current status is in progress  */}
          {userData?.isUploaded &&
            statusFault === ACCORDION_STATUS.IN_PROGRESS && (
              <Box
                id="success_reuploaded_box"
                data-testid="success_reuploaded_box"
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <div>
                  <ProgressContainer data-testid="progresss-container_reupload">
                    <div style={{ flexGrow: '0.2' }}>
                      <img src={xlsxIcon} alt="xlsx-icon" />
                    </div>
                    <Box
                      sx={{
                        flexGrow: '1',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginLeft: '2rem',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <div>
                          {metaFileReupload[0]?.name ?? userData.fileName}
                        </div>
                      </Box>
                      <FileSizeTypography>
                        {formatBytes(
                          metaFileReupload[0]?.size ?? userData.fileSize
                        )}
                      </FileSizeTypography>
                      <StyledLinearProgress
                        variant="determinate"
                        value={Math.round(100)}
                      />
                    </Box>
                    {errorList.length > 0 && isUploadSocketError && (
                      <Box sx={{ flexGrow: '0.2', marginLeft: '2.125rem' }}>
                        <img
                          src={exclamationMark}
                          alt="error-icon"
                          data-testid="tick_icon_reupload"
                        />
                      </Box>
                    )}
                  </ProgressContainer>
                </div>
              </Box>
            )}

          {/* // ? this should be hidden from users view. this is only to provide a reference for reupload after success.
          // ?     only enabled if file uploaded successfully earlier */}
          {userData?.isUploaded && (
            <>
              <Box
                hidden={statusFault === ACCORDION_STATUS.COMPLETED}
                id="success_reupload"
                key={new Date()}
              >
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  maxFiles={1}
                  SubmitButtonComponent={() => null}
                  InputComponent={(inputComponentProps) => {
                    return (
                      <UploadInput
                        id="success_upload_input"
                        hidden={!isUploadSocketError}
                        ref={ref}
                        isReUpload={true}
                        setMetaFileReupload={setMetaFileReupload}
                        {...inputComponentProps}
                      />
                    );
                  }}
                  accept={FILE_CONTENT_TYPES.XLSX}
                  multiple={false}
                  canRestart
                  initialFiles={null}
                />
              </Box>
            </>
          )}
          {isFileUploadedWithoutErrors && (
            <Box sx={{ padding: '.5rem' }}>
              <UserDataTypography>{`File uploaded by ${userData?.firstName} ${userData?.lastName} on ${userData?.date} at ${userData?.time} ${userData?.timezone}`}</UserDataTypography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);
UploadBox.propTypes = {
  fetchDownloadUrl: PropTypes.func,
  entityId: PropTypes.string,
  errorList: PropTypes.array,
  isValidateError: PropTypes.bool,
  validate: PropTypes.func,
  resetValidate: PropTypes.func,
  updateAccordionStatus: PropTypes.func,
  getPresignedUploadUrl: PropTypes.func,
  utility: PropTypes.string,
};

export default UploadBox;
