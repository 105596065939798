import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import WarningIcon from '../../assets/img/warningIcon.svg';
export const AlertBox = ({ message, styleProps, width = 16, height = 16 }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ ...styleProps }}
      data-testid="alert-box"
    >
      <Box display="flex" alignItems="center" marginRight="0.25rem">
        <img
          data-testid="warning-icon"
          src={WarningIcon}
          alt="WarningIcon"
          height={height}
          width={width}
        />
      </Box>
      <Box marginLeft="0.25rem" mt=".1rem">
        <Typography variant="subtitle2">{message}</Typography>
      </Box>
    </Box>
  );
};

AlertBox.propTypes = {
  message: PropTypes.string,
  styleProps: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default AlertBox;
