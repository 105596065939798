import { globalstate$ } from '@smf/ui-util-app';

/**
 * Function to update RxJs status
 * @param {Boolean} status Status to update factory general sidebar status
 */
export const updateConstraintsConfigStatus = (status = false) => {
  const state = globalstate$.getValue();
  globalstate$.next({
    ...state,
    configStatus: { ...state.configStatus, constraints: status },
  });
};

/**
 * Function to update RxJs status
 * @param {Object} data data to global state
 */
export const updateRxjsState = (data) => {
  const state = globalstate$.getValue();
  globalstate$.next({ ...state, ...data });
};

export default {};
