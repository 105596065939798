/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
  ButtonBase,
  FormControl,
  Grid,
  Input,
  styled,
  Typography,
} from '@mui/material';
import React, { forwardRef } from 'react';

import greenUploadIcon from '../../assets/img/greenUploadIcon.svg';
import MESSAGE_STRINGS from '../../constants/en-us';

const CustomImg = styled('img')(() => ({
  width: '3.125rem',
  height: '3.125rem',
}));

const UploadInput = forwardRef(
  (
    {
      accept,
      onFiles,
      getFilesFromEvent,
      setMetaFileReupload,
      isReUpload = true,
      hidden = false,
      id,
    },
    ref
  ) => {
    const handleClickUpload = () => {
      ref?.current?.click();
    };

    const handleChange = (event) => {
      const chosenFile = getFilesFromEvent(event);
      setMetaFileReupload(chosenFile);
      onFiles(chosenFile);
    };
    return (
      <FormControl
        htmlFor="uploadButton"
        style={{ width: '100%', opacity: `${hidden ? 0 : 1}` }}
      >
        <Input
          inputProps={{
            type: 'file',
            accept,
            ref: ref,
          }}
          id="uploadButton"
          name="uploadButton"
          disableUnderline
          onChange={handleChange}
          sx={{ display: 'none' }}
          data-testid="input"
        />
        <ButtonBase
          id="upload_input"
          onClick={handleClickUpload}
          disableRipple
          sx={{
            padding: '0em 6em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          data-testid="button-base"
        >
          <Grid item data-testid="upload-icon">
            <CustomImg src={greenUploadIcon} alt={'upload-icon'} />
          </Grid>
          <Grid item>
            <Typography variant="h6" data-testid="upload-msg">
              {isReUpload
                ? MESSAGE_STRINGS['UploadComponent.reUpload.text']
                : MESSAGE_STRINGS['UploadComponent.upload.XLSX']}
            </Typography>
          </Grid>
        </ButtonBase>
      </FormControl>
    );
  }
);

export default UploadInput;
