import { equipmentConstraintsConfigurationAPIs } from '@smf/ui-util-app';

export const queryConstants = {
  GET_TARGETS_DATA: 'GET_TARGETS_DATA',
  GET_MISC_DATA: 'GET_MISC_DATA',
  GET_ACTUALS_DATA: 'GET_ACTUALS_DATA',
  GET_UOM: 'GET_UOM',
  GET_TIME_RANGE: 'GET_TIME_RANGE',
  GET_STATUS_DATA: 'GET_STATUS_DATA',
  GET_UPLOAD_PRESIGNED_URL: 'GET_UPLOAD_PRESIGNED_URL',
  WS_UPLOAD_VALIDATION: 'WS_UPLOAD_VALIDATION',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  GET_DOWNLOAD_FILE_DATA: 'GET_DOWNLOAD_FILE_DATA',
};

export async function saveTargetData(data) {
  return await equipmentConstraintsConfigurationAPIs.postTargetData(data);
}

export async function retrieveTargetsData(params) {
  return await equipmentConstraintsConfigurationAPIs.getTargetData(params);
}

export async function saveMiscData(data) {
  return await equipmentConstraintsConfigurationAPIs.postMiscData(data);
}

export async function retrieveMiscData(params) {
  return await equipmentConstraintsConfigurationAPIs.getMiscData(params);
}

export async function saveActualsData(data) {
  return await equipmentConstraintsConfigurationAPIs.postActualsData(data);
}

export async function retrieveActualsData(params) {
  return await equipmentConstraintsConfigurationAPIs.getActualsData(params);
}

export async function retrieveConfigStatusData(params) {
  return await equipmentConstraintsConfigurationAPIs.getConfigStatusData(
    params
  );
}

export async function retrieveUOMData() {
  return await equipmentConstraintsConfigurationAPIs.getUoM();
}

export async function retrieveTimeRangeData() {
  return await equipmentConstraintsConfigurationAPIs.getTimeRange();
}

export async function getFaultCodeTemplateFile(params) {
  return await equipmentConstraintsConfigurationAPIs.getFaultCodeFile(params);
}

export async function retrieveUploadInfo(params) {
  // ! TODO: modify the function once the API comes in
  return await equipmentConstraintsConfigurationAPIs.getUploadInfo(params);
}

export async function retrieveUploadPresignedURL(data) {
  return await equipmentConstraintsConfigurationAPIs.postUploadPresignedURL(
    data
  );
}
