/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { Grid, Box, styled, Modal, Typography } from '@mui/material';

import GeneralButton from '../GeneralButton/index';
import infoIconV2 from '../../assets/img/infoIconV2.svg';
import {
  ACCORDION_STATUS,
  BUTTON_LABELS,
  BUTTON_TYPE,
  CONSTRAINT_ACCORDION_TITLES,
} from '../../constants';
import MESSAGE_STRINGS from '../../constants/en-us';

// import PropTypes from 'prop-types';

const PopupModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const PopupBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.customColors.darkishBlackBlue,
  //padding: '1rem',
  borderRadius: '.5rem',
  width: '30rem',
  height: '13.25rem',
}));

const MsgContainerGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
}));

const ButtonContainerGrid = styled(Grid)(() => ({
  padding: '1rem 1rem 1rem 1rem',
}));
const PopupModalBox = forwardRef(
  ({ isModalOpen, setModalOpenStatus = () => {}, handleStatus }, ref) => {
    return (
      <PopupModal
        open={isModalOpen}
        onClose={() => {
          handleStatus(
            ACCORDION_STATUS.COMPLETED,
            CONSTRAINT_ACCORDION_TITLES.FAULT_CODE
          );
          setModalOpenStatus(false);
        }}
        data-testid="popup-modal"
      >
        <PopupBox>
          <Grid container sx={{ padding: '1rem' }}>
            <Grid item sx={{}}>
              <img src={infoIconV2} alt="icons" />
            </Grid>
            <MsgContainerGrid item sx={{ padding: '4px 0px 0px' }}>
              <Typography
                sx={{
                  fontFamily: 'open sans',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '1rem',
                  lineHeight: '1.375rem',
                  width: '133px',
                  padding: '0rem 0rem 0rem 1rem',
                }}
              >
                {MESSAGE_STRINGS['UploadComponent.reUploadModal.confirmText']}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'open sans',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '.875rem',
                  lineHeight: '1.1875rem',

                  width: '339px',

                  padding: '.9rem 0rem 0rem 1rem',
                }}
              >
                {MESSAGE_STRINGS['UploadComponent.reUploadModal.popupMsg']}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'open sans',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '.875rem',
                  lineHeight: '1.1875rem',

                  width: '407px',

                  padding: '.9rem 0rem 0rem 1rem',
                }}
              >
                {MESSAGE_STRINGS['UploadComponent.reUploadModal.confirmMsg']}
              </Typography>
            </MsgContainerGrid>
          </Grid>

          <ButtonContainerGrid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <GeneralButton
                data-testid="popup-modal-no-button"
                onClick={() => {
                  handleStatus(
                    ACCORDION_STATUS.COMPLETED,
                    CONSTRAINT_ACCORDION_TITLES.FAULT_CODE
                  );
                  setModalOpenStatus(false);
                }}
                text={BUTTON_LABELS.NO}
                disable={false}
                type={BUTTON_TYPE.SECONDARY}
              />
            </Grid>
            <Grid item>
              <GeneralButton
                data-testid="popup-modal-yes-button"
                onClick={() => {
                  handleStatus(
                    ACCORDION_STATUS.IN_PROGRESS,
                    CONSTRAINT_ACCORDION_TITLES.FAULT_CODE
                  );
                  // ? trigger reupload
                  if (ref?.current) {
                    ref.current.click();
                  }
                  // setModalOpenStatus(false)
                }}
                type={BUTTON_TYPE.PRIMARY}
                text={BUTTON_LABELS.YES}
                disable={false}
              />
            </Grid>
          </ButtonContainerGrid>
        </PopupBox>
      </PopupModal>
    );
  }
);

export default PopupModalBox;
