import { Box, List, ListItem, styled } from '@mui/material';
import PropTypes from 'prop-types';
import exclamationMark from '../../assets/img/exclamationMark.svg';
import WarningIcon from '../../assets/img/warningIcon.svg';

const ErrorBorder = styled(Box)(({ theme, warnFlag }) => ({
  display: 'flex',
  flexDirection: 'row',
  background: warnFlag ? 'rgba(255, 255, 0, 0.05)' : 'rgba(218, 41, 28, 0.05)',
  borderRadius: '4px',
  minHeight: '3em',
  border: warnFlag
    ? '1px solid orange'
    : `1px solid ${theme.palette.border.lavaRed}`,
}));

const ErrorList = ({ errorList, warnFlag }) => {
  const isSingleError = errorList.length === 1;
  return (
    <Box sx={{ width: '100%', padding: '0.625rem' }}>
      <ErrorBorder warnFlag={warnFlag}>
        {!warnFlag ? (
          <img
            style={{
              height: '1.5em',
              width: '1.5em',
              margin: '0.625em 1em 1em 1em',
            }}
            src={exclamationMark}
            alt="exclamationMark"
          />
        ) : (
          <img
            style={{
              height: '1.5em',
              width: '1.5em',
              margin: '0.625em 1em 1em 1em',
            }}
            src={WarningIcon}
            alt="WarningIcon"
          />
        )}
        <List sx={{ margin: 'auto 0', paddingTop: '0' }}>
          {errorList.map((errMsg, idx) => {
            let errorListItems;
            let isErrorHeading = false;
            if (errMsg.includes(':')) {
              errorListItems = errMsg.split(':');
              isErrorHeading = true;
            } else if (errMsg.includes('!')) {
              errorListItems = errMsg.split('!');
              isErrorHeading = true;
            } else {
              errorListItems = [errMsg];
            }

            return (
              <ListItem
                sx={{
                  paddingLeft: '0.2rem',
                  fontSize: '0.875rem ',
                  fontWeight: '400 ',
                  fontFamily: 'Open Sans ',
                  lineHeight: '1.192rem ',
                  color: 'white',
                  paddingRight: '0',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  marginTop: '0.625rem',
                  height: 'fit-content',
                }}
                key={idx}
              >
                <p>
                  {!isSingleError && <b>{`${idx + 1}. `} </b>}
                  {warnFlag
                    ? isErrorHeading && (
                        <b>{`${errorListItems[0].replace(/<\/?b>/g, '')}: `}</b>
                      )
                    : isErrorHeading && <b>{`${errorListItems[0]}: `}</b>}
                  {isErrorHeading &&
                    !warnFlag &&
                    errorListItems.slice(1).map((item, idx) => {
                      return idx === 0 ? <>{` ${item}`}</> : <>{`: ${item}`}</>;
                    })}
                  {isErrorHeading &&
                    warnFlag &&
                    errorListItems.slice(1).map((item, idx) => {
                      const substrings = item.split(/(<b>.*?<\/b>)|<\/b>/);

                      return idx === 0 ? (
                        substrings.length > 3 ? (
                          <>
                            <b>{` ${substrings[0]}`}</b>
                            {` ${substrings[2]}`}
                            <b>{` ${substrings[3].replace(/<\/?b>/g, '')}`}</b>
                            {` ${substrings[4]}`}
                          </>
                        ) : (
                          <>
                            <b>{` ${substrings[0]}`}</b>
                            {` ${substrings[2]}`}
                          </>
                        )
                      ) : (
                        <>{`: ${substrings[0]}`}</>
                      );
                    })}
                  {!isErrorHeading && <>{errorListItems[0]}</>}
                </p>
              </ListItem>
            );
          })}
        </List>
      </ErrorBorder>
    </Box>
  );
};
export default ErrorList;

ErrorList.propTypes = {
  errorList: PropTypes.array,
  warnFlag: PropTypes.bool,
};
