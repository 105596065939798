import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { Box, Typography, Grid, styled } from '@mui/material';

import GeneralTextField from '../../components/GeneralTextField';
import GeneralButton from '../../components/GeneralButton';
import HourMinInputs from '../../components/HourMinInputs';
import ErrorBox from '../../components/ErrorBox';

import {
  BUTTON_LABELS,
  BUTTON_TYPE,
  TARGET_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
  ACCORDION_STATUS,
  CONSTRAINT_ACCORDION_TITLES,
} from '../../constants';
import {
  queryConstants,
  retrieveTargetsData,
  saveTargetData,
} from '../../utils/apihelper';

import { useRxjsState } from '../../utils/useRxjsState';
import { CONSTANT_MESSAGES, ERROR_MESSAGES } from '../../constants/en-us';
import { useToastContext } from '../../context/toastContext';

import PropTypes from 'prop-types';

const ButtonBox = styled(Box)(() => ({
  margin: '0 1rem .625rem 0',
  height: '2.25rem',
}));

const SubConfig = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.customColors.wrapperGrey}`,
}));

const TargetsContainer = ({ handleStatus = () => {} }) => {
  const [openConstraintsCount, setOpenConstraintsCount] = useState(null);
  const [closedConstraintsCount, setClosedConstraintsCount] = useState(null);
  const [totalOpenConstraintsDuration, setTotalOpenConstraintsDuration] =
    useState({ hr: null, min: null });
  const [averageConstraintResolutionTime, setAverageConstraintResolutionTime] =
    useState({ hr: null, min: null });

  const [isErrorVisibilityEnabled, setisErrorVisibilityEnabled] =
    useState(false);
  const [isInitialEntry, setisInitialEntry] = useState(false);

  const { toastDispatch } = useToastContext();

  const isBothHourAndMinZero = (totalValue) => {
    return parseInt(totalValue.hr) === 0 && parseInt(totalValue.min) === 0;
  };

  const isTimeError = (timeValue, totalValue) => {
    if (timeValue === null && isInitialEntry) {
      return false;
    }
    if (timeValue % 1 > 0) {
      return true;
    }
    if (isBothHourAndMinZero(totalValue)) {
      return true;
    }
    if (!timeValue) {
      return true;
    } else {
      return false;
    }
  };

  const isWholeNumberOrEmpty = (value) => {
    if (value === null && isInitialEntry) {
      return false;
    }
    // ? is a non-whole number ?
    if (value % 1 > 0) {
      return true;
    }
    // ? is empty?
    if (!value) {
      return true;
    }
    return false;
  };

  const isErrorPresentInInput = (label, isHour = false) => {
    if (isErrorVisibilityEnabled) {
      switch (label) {
        case TARGET_CONSTANTS.SUBHEADERS.OPEN_CONSTRAINTS:
          return isWholeNumberOrEmpty(openConstraintsCount);
        case TARGET_CONSTANTS.SUBHEADERS.CLOSED_CONSTRAINTS:
          return isWholeNumberOrEmpty(closedConstraintsCount);
        case TARGET_CONSTANTS.SUBHEADERS.TOTAL_DURATION:
          return isTimeError(
            isHour
              ? totalOpenConstraintsDuration.hr
              : totalOpenConstraintsDuration.min,
            totalOpenConstraintsDuration
          );

        case TARGET_CONSTANTS.SUBHEADERS.AVERAGE_DURATION:
          return isTimeError(
            isHour
              ? averageConstraintResolutionTime.hr
              : averageConstraintResolutionTime.min,
            averageConstraintResolutionTime
          );
      }
    }
    return false;
  };

  const isAnyErrorPresent = () => {
    return (
      isErrorPresentInInput(
        TARGET_CONSTANTS.SUBHEADERS.OPEN_CONSTRAINTS,
        false
      ) ||
      isErrorPresentInInput(
        TARGET_CONSTANTS.SUBHEADERS.CLOSED_CONSTRAINTS,
        false
      ) ||
      isErrorPresentInInput(TARGET_CONSTANTS.SUBHEADERS.TOTAL_DURATION, true) ||
      isErrorPresentInInput(
        TARGET_CONSTANTS.SUBHEADERS.TOTAL_DURATION,
        false
      ) ||
      isErrorPresentInInput(
        TARGET_CONSTANTS.SUBHEADERS.AVERAGE_DURATION,
        true
      ) ||
      isErrorPresentInInput(TARGET_CONSTANTS.SUBHEADERS.AVERAGE_DURATION, false)
    );
  };

  const getTimeErrorMessage = (timeValue, totalValue) => {
    if (!timeValue || isBothHourAndMinZero(totalValue)) {
      return ERROR_MESSAGES.REQUIRED;
    }
    if (timeValue % 1 > 0) {
      return ERROR_MESSAGES.NOT_WHOLE_NUMBER;
    }
  };

  const getGenericErrorMessage = (value) => {
    if (!value) {
      return ERROR_MESSAGES.REQUIRED;
    }
    if (value % 1 > 0) {
      return ERROR_MESSAGES.NOT_WHOLE_NUMBER;
    }
  };

  const getErrorMessage = (label, isHour = false) => {
    switch (label) {
      case TARGET_CONSTANTS.SUBHEADERS.OPEN_CONSTRAINTS:
        return getGenericErrorMessage(openConstraintsCount);
      case TARGET_CONSTANTS.SUBHEADERS.CLOSED_CONSTRAINTS:
        return getGenericErrorMessage(closedConstraintsCount);
      case TARGET_CONSTANTS.SUBHEADERS.TOTAL_DURATION:
        return getTimeErrorMessage(
          isHour
            ? totalOpenConstraintsDuration.hr
            : totalOpenConstraintsDuration.min,
          totalOpenConstraintsDuration
        );

      case TARGET_CONSTANTS.SUBHEADERS.AVERAGE_DURATION:
        return getTimeErrorMessage(
          isHour
            ? averageConstraintResolutionTime.hr
            : averageConstraintResolutionTime.min,
          averageConstraintResolutionTime
        );
    }
  };

  const getTimeErrorMessageWithWidth = (label, isHour) => {
    return isErrorPresentInInput(label, isHour) === true
      ? getErrorMessage(label, isHour)
      : null;
  };

  const { rxjsState } = useRxjsState();
  const queryClient = useQueryClient();

  const { mutate: postTargetsDataMutate } = useMutation(
    (data) => saveTargetData(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryConstants.GET_STATUS_DATA);
        handleStatus(
          ACCORDION_STATUS.COMPLETED,
          CONSTRAINT_ACCORDION_TITLES.TARGETS
        );
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: CONSTANT_MESSAGES.TARGETS.POST_SUCCESS,
          },
        });
      },
      onError: (error) => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message:
              error?.response?.data?.message ??
              CONSTANT_MESSAGES.DEFAULT_POST_API_ERROR_MESSAGE,
          },
        });
      },
    }
  );

  useQuery(
    [queryConstants.GET_TARGETS_DATA],
    async () => {
      return await retrieveTargetsData({ plantId: rxjsState.factoryId });
    },
    {
      enabled: !!rxjsState.factoryId,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const modifiedResponse = {
          openConstraints: response.target.openConstraints ?? null,
          closedConstraints: response.target.closedConstraints ?? null,
          totalDuration: {
            hr: response.target.openConstraintsDurationValue
              ? Math.floor(
                  response.target.openConstraintsDurationValue / 60
                ).toString()
              : null,
            min: response.target.openConstraintsDurationValue
              ? Math.round(
                  response.target.openConstraintsDurationValue % 60
                ).toString()
              : null,
          },
          averageDuration: {
            hr: response.target.averageResolutionTimeValue
              ? Math.floor(
                  response.target.averageResolutionTimeValue / 60
                ).toString()
              : null,
            min: response.target.averageResolutionTimeValue
              ? Math.round(
                  response.target.averageResolutionTimeValue % 60
                ).toString()
              : null,
          },
        };
        if (
          modifiedResponse.openConstraints === null &&
          modifiedResponse.closedConstraints === null &&
          modifiedResponse.totalDuration.hr === null &&
          modifiedResponse.totalDuration.min === null &&
          modifiedResponse.averageDuration.hr === null &&
          modifiedResponse.averageDuration.min === null
        ) {
          setisInitialEntry(true);
        }
        setOpenConstraintsCount(modifiedResponse.openConstraints);
        setClosedConstraintsCount(modifiedResponse.closedConstraints);
        setTotalOpenConstraintsDuration(modifiedResponse.totalDuration);
        setAverageConstraintResolutionTime(modifiedResponse.averageDuration);
      },
    }
  );

  const handleChange = (e, label, isHour = false) => {
    handleStatus(
      ACCORDION_STATUS.IN_PROGRESS,
      CONSTRAINT_ACCORDION_TITLES.TARGETS
    );
    // ? enabling error checking
    setisErrorVisibilityEnabled(true);

    const value = e.target.value;
    // ? ignore negative values and values above 6 digits.

    switch (label) {
      case TARGET_CONSTANTS.SUBHEADERS.OPEN_CONSTRAINTS:
        if (
          value >= 0 &&
          value <= 99999 &&
          value.length < 6 &&
          value !== '-0'
        ) {
          setOpenConstraintsCount(value);
        }
        break;
      case TARGET_CONSTANTS.SUBHEADERS.CLOSED_CONSTRAINTS:
        if (
          value >= 0 &&
          value <= 99999 &&
          value.length < 6 &&
          value !== '-0'
        ) {
          setClosedConstraintsCount(value);
        }
        break;
      case TARGET_CONSTANTS.SUBHEADERS.TOTAL_DURATION:
        if (isHour) {
          setTotalOpenConstraintsDuration({
            ...totalOpenConstraintsDuration,
            hr: value,
          });
        } else {
          setTotalOpenConstraintsDuration({
            ...totalOpenConstraintsDuration,
            min: value,
          });
        }
        break;
      case TARGET_CONSTANTS.SUBHEADERS.AVERAGE_DURATION:
        if (isHour) {
          setAverageConstraintResolutionTime({
            ...averageConstraintResolutionTime,
            hr: value,
          });
        } else {
          setAverageConstraintResolutionTime({
            ...averageConstraintResolutionTime,
            min: value,
          });
        }
        break;

      default:
        break;
    }
  };

  const handleSave = () => {
    setisInitialEntry(false);
    // ? enable errors on direct save click.
    setisErrorVisibilityEnabled(true);

    // ? post only if there are no errors
    if (isAnyErrorPresent() || !isErrorVisibilityEnabled) {
      return;
    }

    if (
      openConstraintsCount === null ||
      closedConstraintsCount === null ||
      totalOpenConstraintsDuration.hr === null ||
      totalOpenConstraintsDuration.min === null ||
      averageConstraintResolutionTime.hr === null ||
      averageConstraintResolutionTime.min === null
    ) {
      return;
    }

    const dataToSend = {
      plantId: rxjsState.factoryId,
      configType: 'target',
      emailId: rxjsState.userData?.email,
      data: [
        {
          parameterName: 'closedConstraints',
          value: parseInt(closedConstraintsCount).toString(),
          uom: 'units',
        },
        {
          parameterName: 'openClosedConstraints',
          value: parseInt(openConstraintsCount).toString(),
          uom: 'units',
        },
        {
          parameterName: 'openConstraintsDuration',
          value:
            parseInt(totalOpenConstraintsDuration.hr * 60) +
            parseInt(totalOpenConstraintsDuration.min),
          uom: 'minutes',
        },
        {
          parameterName: 'averageResolutionTime',
          value:
            parseInt(averageConstraintResolutionTime.hr * 60) +
            parseInt(averageConstraintResolutionTime.min),
          uom: 'minutes',
        },
      ],
    };
    postTargetsDataMutate(dataToSend);
  };

  return (
    <Box
      data-testid="container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/*Header*/}
      <Grid
        container
        item
        sx={{
          backgroundColor: (theme) => theme.customColors.darkishBlackBlue,
          height: '3.5rem',
          alignItems: 'center',
          color: (theme) => theme.customColors.lightUnitGrey,
        }}
      >
        <Grid
          item
          sx={{
            width: '69.133%',
            paddingLeft: '1.25rem',
          }}
        >
          <Typography variant="subtitle2">
            {TARGET_CONSTANTS.HEADERS.PARAMETERS}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            {TARGET_CONSTANTS.HEADERS.EQUIPMENT_CONSTRAINT}
          </Typography>
        </Grid>
      </Grid>

      <>
        {/*Open Constraints*/}
        <SubConfig>
          <Grid
            container
            item
            sx={{
              backgroundColor: (theme) => theme.customColors.blackGrey,
              alignItems: 'center',
            }}
          >
            <Grid
              item
              sx={{
                width: '69.133%',
                paddingLeft: '1.25rem',
              }}
            >
              <Typography variant="subtitle2" sx={{ padding: '.8438rem 0' }}>
                {TARGET_CONSTANTS.SUBHEADERS.OPEN_CONSTRAINTS}
              </Typography>
            </Grid>

            <Grid
              item
              container
              direction="column"
              justifyContent="flex-start"
              sx={{ width: '10rem', marginTop: '.5rem' }}
            >
              <Grid item>
                <GeneralTextField
                  id="open_constraints"
                  placeholder="-"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={openConstraintsCount}
                  error={isErrorPresentInInput(
                    TARGET_CONSTANTS.SUBHEADERS.OPEN_CONSTRAINTS,
                    false
                  )}
                  onChange={(e) =>
                    handleChange(
                      e,
                      TARGET_CONSTANTS.SUBHEADERS.OPEN_CONSTRAINTS,
                      false
                    )
                  }
                />
              </Grid>
              {isErrorPresentInInput(
                TARGET_CONSTANTS.SUBHEADERS.OPEN_CONSTRAINTS,
                false
              ) && (
                <Grid item>
                  <ErrorBox
                    errorMessage={getErrorMessage(
                      TARGET_CONSTANTS.SUBHEADERS.OPEN_CONSTRAINTS,
                      false
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </SubConfig>

        {/*Closed Constraints*/}
        <SubConfig>
          <Grid
            container
            item
            sx={{
              backgroundColor: (theme) => theme.customColors.blackGrey,
              alignItems: 'center',
            }}
          >
            <Grid
              item
              sx={{
                width: '69.133%',
                paddingLeft: '1.25rem',
              }}
            >
              <Typography variant="subtitle2" sx={{ padding: '.8438rem 0' }}>
                {TARGET_CONSTANTS.SUBHEADERS.CLOSED_CONSTRAINTS}
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent="flex-start"
              sx={{ width: '10rem', marginTop: '.5rem' }}
            >
              <Grid item>
                <GeneralTextField
                  id="closed_constraints"
                  placeholder="-"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={closedConstraintsCount}
                  error={isErrorPresentInInput(
                    TARGET_CONSTANTS.SUBHEADERS.CLOSED_CONSTRAINTS,
                    false
                  )}
                  onChange={(e) =>
                    handleChange(
                      e,
                      TARGET_CONSTANTS.SUBHEADERS.CLOSED_CONSTRAINTS,
                      false
                    )
                  }
                />
              </Grid>
              {isErrorPresentInInput(
                TARGET_CONSTANTS.SUBHEADERS.CLOSED_CONSTRAINTS,
                false
              ) && (
                <Grid item>
                  <ErrorBox
                    errorMessage={getErrorMessage(
                      TARGET_CONSTANTS.SUBHEADERS.CLOSED_CONSTRAINTS,
                      false
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </SubConfig>

        {/*Total Duration of Open Constraints*/}
        <SubConfig>
          <Grid
            container
            item
            sx={{
              backgroundColor: (theme) => theme.customColors.blackGrey,
              alignItems: 'center',
            }}
          >
            <Grid
              item
              sx={{
                width: '69.133%',
                paddingLeft: '1.25rem',
              }}
            >
              <Typography variant="subtitle2" sx={{ padding: '.8438rem 0' }}>
                {TARGET_CONSTANTS.SUBHEADERS.TOTAL_DURATION}
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent="flex-start"
              sx={{ width: '13rem', marginTop: '.5rem' }}
            >
              <HourMinInputs
                id="total"
                hr={totalOpenConstraintsDuration.hr}
                min={totalOpenConstraintsDuration.min}
                label={TARGET_CONSTANTS.SUBHEADERS.TOTAL_DURATION}
                onChange={handleChange}
                isHrError={isErrorPresentInInput(
                  TARGET_CONSTANTS.SUBHEADERS.TOTAL_DURATION,
                  true
                )}
                isMinError={isErrorPresentInInput(
                  TARGET_CONSTANTS.SUBHEADERS.TOTAL_DURATION,
                  false
                )}
              />

              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <ErrorBox
                    maxWidth="4rem"
                    errorMessage={getTimeErrorMessageWithWidth(
                      TARGET_CONSTANTS.SUBHEADERS.TOTAL_DURATION,
                      true
                    )}
                  />
                </Grid>

                <Grid item>
                  <ErrorBox
                    maxWidth="4rem"
                    errorMessage={getTimeErrorMessageWithWidth(
                      TARGET_CONSTANTS.SUBHEADERS.TOTAL_DURATION,
                      false
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SubConfig>

        {/*Average Constraint Resolution Time*/}
        <SubConfig>
          <Grid
            container
            item
            sx={{
              backgroundColor: (theme) => theme.customColors.blackGrey,
              alignItems: 'center',
            }}
          >
            <Grid
              item
              sx={{
                width: '69.133%',
                paddingLeft: '1.25rem',
              }}
            >
              <Typography variant="subtitle2" sx={{ padding: '.8438rem 0' }}>
                {TARGET_CONSTANTS.SUBHEADERS.AVERAGE_DURATION}
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent="flex-start"
              sx={{ width: '13rem', marginTop: '.5rem' }}
            >
              <HourMinInputs
                id="average"
                hr={averageConstraintResolutionTime.hr}
                min={averageConstraintResolutionTime.min}
                label={TARGET_CONSTANTS.SUBHEADERS.AVERAGE_DURATION}
                onChange={handleChange}
                isHrError={isErrorPresentInInput(
                  TARGET_CONSTANTS.SUBHEADERS.AVERAGE_DURATION,
                  true
                )}
                isMinError={isErrorPresentInInput(
                  TARGET_CONSTANTS.SUBHEADERS.AVERAGE_DURATION,
                  false
                )}
              />
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                spacing={1}
              >
                {
                  <Grid item>
                    <ErrorBox
                      maxWidth="4rem"
                      errorMessage={getTimeErrorMessageWithWidth(
                        TARGET_CONSTANTS.SUBHEADERS.AVERAGE_DURATION,
                        true
                      )}
                    />
                  </Grid>
                }
                {
                  <Grid item>
                    <ErrorBox
                      maxWidth="4rem"
                      errorMessage={getTimeErrorMessageWithWidth(
                        TARGET_CONSTANTS.SUBHEADERS.AVERAGE_DURATION,
                        false
                      )}
                    />
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </SubConfig>

        <Grid
          container
          item
          sx={{
            backgroundColor: (theme) => theme.customColors.headerBlack,
            height: '3.5rem',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonBox>
            <GeneralButton
              disable={isAnyErrorPresent()}
              type={BUTTON_TYPE.PRIMARY}
              text={BUTTON_LABELS.SAVE}
              onClick={handleSave}
            />
          </ButtonBox>
        </Grid>
      </>
    </Box>
  );
};
TargetsContainer.propTypes = {
  handleStatus: PropTypes.func,
};
export default TargetsContainer;
